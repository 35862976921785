import React from 'react';
// import { ImPriceTags } from "react-icons/im";
import price from "./asset/price.svg"
import { NavLink } from 'react-router-dom';

function Card({prop}) {
  return (
    <NavLink to={`/${prop.link}`}>
        <div className="w-[330px] md:w-[350px] flex mb-[50px] flex-col bg-gradient-to-b from-white to-green-50 rounded-2xl shadow-lg overflow-hidden hover:shadow-2xl transform transition duration-300 ease-in-out">
    <div className="h-[30vh] w-full">
        <img className="object-cover h-full w-full" src={prop.img} alt={prop.title} />
    </div>
    <div className="flex flex-col px-6 py-4 space-y-0 items-center justify-center">
        <div className="text-2xl font-bold text-green-800 poppins">
            {prop.title}
        </div>
        <div className="flex flex-col space-y-0">
            <p className="text-lg text-center font-semibold text-gray-500 poppins">
                {prop.plot ? prop.plot : prop.status}
            </p>
            <p className="flex items-center gap-2 mr-3 py-1 justify-center text-xl text-green-800">
                <img className='h-[30px] mt-[10px]' src={prop.price && price} alt="" />
                {prop.price ? <span className="font-bold text-3xl">{prop.price}</span> :
                <span className="font-bold text-xl text-gray-500">{prop.plotNo}</span>}
            </p>
        </div>
        <button className="w-full  text-lg font-bold rounded-full text-green-700 py-2 mt-4 border-2 border-green-700 hover:bg-green-700 bg-transparent hover:text-white transition-all duration-300 ease-in-out">
            View Details
        </button>
    </div>
</div>
</NavLink>

  )
}

export default Card;

