import React, { useState } from 'react'
import bottomline from "../newComponents/asset/bottomline.png"

function Projectreview() {
  const [showMore, setShowMore] = useState(false);
  return (
    <div className='flex flex-col overflow-y-auto text-justify mx-auto h-auto mb-12 mt-5 md:mt-8'>
      <div className="flex flex-col justify-center items-center">
        <h1 className="text-2xl lg:text-5xl font-bold text-center poppins text-green-700 uppercase tracking-wider yeseva">
          Project Overview
        </h1>
        <img className="ml-[-10px] mb-3 md:block hidden" src={bottomline} alt="" />
        <img className="ml-[-10px] mb-3 md:hidden block w-[300px]" src={bottomline} alt="" />
      </div>
      <p className='poppins max-w-7xl mx-auto px-5 text-sm md:text-lg text-justify font-base text-gray-600'>
        Welcome to Indus Valley Mukteshwar, a place that connects you to the majestic beauty of the Himalayas. Spanning across 10 acres of land, this private gated township in the lap of the Himalayas can be a perfect destination for you to invest in your Himalayan facing dream house.
        Located at a distance of 326 Km (7-hour drive) from Delhi and a 1.5-hour drive from Nainital, this property is nestled at 7,358 ft above sea level. It offers breathtaking 360-degree views of the snow-covered Nanda Devi Himalayan range, surrounded by pine and deodar trees, valleys, and fruit orchards.
        <p className=' text-center mt-5 text-green-700 text-2xl lg:text-4xl yeseva'>"A RESIDENCE OF LORD SHIVA "</p>
        <span className='hidden md:block'>
            <br />
            This exclusive township is diversified to offer various accommodation options: studios and apartments, custom-built cottages, and premium semi-wooden villas. All the necessary government approvals, including the NOC from RERA, are in place.
            The amenities that come along with our gated community include electricity and water connections, dedicated parking spaces, a clubhouse, a restaurant, solar street lights, CCTV security, and much more.
            These Himalayan-facing cottages serve three purposes: vacation homes, retirement homes, and opportunities for those who want to run their homestay and seek a return on investment.
            Discover peace; Discover the Indus Valley.
          </span>


        {!showMore && (
          <span className="block w-full lg:hidden text-green-600 text-center cursor-pointer mt-2" onClick={() => setShowMore(true)}>
            Read More
          </span>
        )}
        {showMore && (
          <span>
            <br />
            This exclusive township is diversified to offer various accommodation options: studios and apartments, custom-built cottages, and premium semi-wooden villas. All the necessary government approvals, including the NOC from RERA, are in place.
            The amenities that come along with our gated community include electricity and water connections, dedicated parking spaces, a clubhouse, a restaurant, solar street lights, CCTV security, and much more.
            These Himalayan-facing cottages serve three purposes: vacation homes, retirement homes, and opportunities for those who want to run their homestay and seek a return on investment.
            Discover peace; Discover the Indus Valley.
          </span>
        )}
      </p>
    </div>
  )
}

export default Projectreview;
