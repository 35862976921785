import React from 'react'
import route from "./asset/route-map.jpg"
import route2 from "./asset/routemap-2.jpg"
import bottomline from "../newComponents/asset/bottomline.png"


function RouteMap() {
  return (
    <>
    <div className=''>
      <div className='flex flex-col mt-8 justify-center items-center'>
        <p className='text-3xl lg:text-4xl font-bold text-center poppins text-green-700 uppercase tracking-wider yeseva'>
          Route Map
        </p>
        <img className="ml-[-10px] mb-3 md:block hidden" src={bottomline} alt="" />
        <img className="ml-[-10px] mb-3 md:hidden block w-[300px]" src={bottomline} alt="" />
      </div>
    <div className='w-full px-5'>
        <img className='w-full rounded-xl md:block hidden' src={route} alt="delhi to mukteshwar route map" />
        <img className='w-full rounded-xl  md:hidden block' src={route2} alt="delhi to mukteshwar route map" />
    </div>
    </div>
    </>
  )
}

export default RouteMap