export const Data = {
    "Studio_1bhk_Apart_layout":{
    alt:"aparment near nainital",
    title: "Studio Retreat with Himalayan Horizons",
    plot: "Plot No - 69 : F1 / F2",
    price: "36 Lacs",
    yt: "https://www.youtube.com/embed/ChUDG23veW4",
    meta:{
      title:"Studio and 1BHK Apartments with Modern Layouts",
      des:"SExplore the spacious and stylish studio and 1BHK apartments at Indus Valley Mukteshwar. Perfect for modern living with contemporary amenities"
    },
    images: [
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot69/1.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot69/2.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot69/3.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot69/4.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot69/5.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot69/6.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot69/7.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot69/8.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot69/9.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot69/10.jpg",
    ],
    features: {
      keyFeatures: [
        { icon: "FaHome", label: "Super Built-up Area", value: "388 sq ft" },
        { icon: "FaBed", label: "Fully Furnished Studio Apartment", value: "" },
      ],
      livingAreaKitchen: [
        { icon: "FaBed", label: "Seating", value: "Sofa" },
        { icon: "FaTv", label: "Entertainment", value: "LCD Unit" },
        { icon: "FaHome", label: "Design", value: "Integrated Modular Kitchen" },
        { icon: "FaFire", label: "Ventilation", value: "Chimney" },
        { icon: "FaWater", label: "Hot Water", value: "Geyser" },
      ],
      bedroom: [
        { icon: "FaBed", label: "Furnishing", value: "King Size Bed" },
        { icon: "FaHome", label: "Space", value: "1 Bedroom with Attached Washroom" },
        { icon: "FaEye", label: "View", value: "Bay Windows with Nanda Devi View" },
        { icon: "MdStorage", label: "Storage", value: "Bedside Table & Wardrobe" },
      ],
      bathroom: [
        { icon: "FaBath", label: "Quality", value: "High-end Fixtures & Fittings" },
        { icon: "MdStorage", label: "Storage", value: "Cabinets for Toiletries" },
      ],
      additionalFeatures: [
        { icon: "FaCar", label: "Parking", value: "Personal Parking Available for Purchase" },
        { icon: "FaEye", label: "Windows", value: "UPVC Fenesta Windows with Toughened Glass" },
        { icon: "FaHome", label: "Flooring", value: "Vitrified Tiles and Wooden Flooring" },
      ],
    },
    floorPlan:[
      "https://d2ehq5aws28ia0.cloudfront.net/floorPlan/floor69.webp"
    ],
    floorPlanPdf: "https://d2ehq5aws28ia0.cloudfront.net/pdf2/SITE+A+PLOT+69.pdf",
  },
  "Plot_09":{
    "title": "1BHK Apartment: Blissful Hillside Haven",
    alt:"cottage in nainital",
    "plot": "Plot No - 09",
    "price": "35 Lacs",
    yt: "https://www.youtube.com/embed/wMewFF9S3RU",
    meta:{
      title:"1 BHK Apartment - Blissful Hillside Haven",
      des:"Looking for 1 BHK Apartment in Uttarakhand? Indus Valley offers great potential for your dream home in a beautiful, natural environment."
    },
    "images": [
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot09/1.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot09/2.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot09/3.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot09/4.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot09/5.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot09/6.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot09/8.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot09/12.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot09/10.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot09/11.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot09/9.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot09/7.jpg",
    ],
    "features": {
      "keyFeatures": [
        { "icon": "FaHome", "label": "Super Built-up Area", "value": "860 sq ft" },
        { "icon": "FaBed", "label": "Fully Furnished Apartment", "value": "" }
      ],
      "livingAreaKitchen": [
        { "icon": "FaCouch", "label": "Seating", "value": "Dining Table, Sofa" },
        { "icon": "FaTv", "label": "Entertainment", "value": "LCD Unit" },
        { "icon": "FaHome", "label": "Design", "value": "Integrated Modular Kitchen" },
        { "icon": "FaFire", "label": "Ventilation", "value": "Chimney" },
        { "icon": "FaWater", "label": "Hot Water", "value": "Geyser" },
        { "icon": "FaWind", "label": "Air Circulation", "value": "Exhaust Fan" }
      ],
      "bedroom": [
        { "icon": "FaBed", "label": "Furnishing", "value": "King Size Bed" },
        { "icon": "FaHome", "label": "Space", "value": "1 Bedroom with Attached Washroom" },
        { "icon": "FaEye", "label": "View", "value": "Bay Windows Offering Nanda Devi Himalayan View" },
        { "icon": "MdStorage", "label": "Storage", "value": "Bedside Table & Wardrobe" }
      ],
      "bathroom": [
        { "icon": "FaBath", "label": "Quantity", "value": "2 Washrooms (1 Common, 1 Attached)" },
        { "icon": "FaBath", "label": "Quality", "value": "High-end Fixtures & Fittings" },
        { "icon": "MdStorage", "label": "Storage", "value": "Cabinets for Toiletries" }
      ],
      "additionalFeatures": [
        { "icon": "FaCar", "label": "Parking", "value": "Personal Parking Available for Purchase" },
        { "icon": "FaEye", "label": "Windows", "value": "UPVC Fenesta Windows with Toughened Glass" },
        { "icon": "FaHome", "label": "Flooring", "value": "Vitrified Tiles and Wooden Flooring" }
      ]
    },
    floorPlan:[
      "https://d2ehq5aws28ia0.cloudfront.net/floorPlan/plot902.webp"
    ],
    "floorPlanPdf": "https://d2ehq5aws28ia0.cloudfront.net/pdf2/SITE+A+PLOT+9.pdf",
  },
  "Plot_01":{
    "title": "2BHK Apartment: Cozy Mountain Escape",
    "plot": "Plot No - 01 (B)",
    "price": "55 Lacs",
    alt:"Home in Uttrakhand",
    yt:"https://www.youtube.com/embed/6iOp7AhhsTc",
    meta:{
      title:"2 BHK Cottage in Mukteshwar Uttarakhand",
      des:"Explore 2 BHK Cottage in Mukteshwar at Indus Valley Mukteshwar- premium cottage for sale in a peaceful, scenic environment. Perfect for your dream home"
    },
    "images": [
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot01/1.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot01/2.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot01/5.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot01/3.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot01/4.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot01/6.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot01/7.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot01/8.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot01/9.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot01/10.jpg",
    ],
    "features": {
      "keyFeatures": [
        { "icon": "FaHome", "label": "Super Built-up Area", "value": "1340 sq ft" },
        { "icon": "FaMountain", "label": "Land Area", "value": "170 sq yard" },
        { "icon": "FaCouch", "label": "Furnishings", "value": "Optional" },
        { "icon": "FaEye", "label": "View", "value": "Bay Windows with Nanda Devi Himalayan View" },
        { "icon": "FaFire", "label": "Cozy Fireplace", "value": "Added Warmth and Ambiance" }
      ],
      "livingAreaKitchen": [
        { "icon": "FaCouch", "label": "Seating", "value": "Dining Table, Sofa" },
        { "icon": "FaTv", "label": "Entertainment", "value": "LCD Unit" },
        { "icon": "FaHome", "label": "Design", "value": "Integrated Modular Kitchen" },
        { "icon": "FaFire", "label": "Ventilation", "value": "Chimney" },
        { "icon": "FaWater", "label": "Hot Water", "value": "Geyser" },
        { "icon": "FaWind", "label": "Air Circulation", "value": "Exhaust Fan" },
        { "icon": "FaTint", "label": "Additional", "value": "Water Filter" }
      ],
      "bedroom": [
        { "icon": "FaBed", "label": "Furnishing", "value": "King Size Beds" },
        { "icon": "FaHome", "label": "Space", "value": "2 Bedrooms with Attached Baths" },
        { "icon": "FaEye", "label": "View", "value": "Bay Windows in Bedroom with Nanda Devi Himalayan View" },
        { "icon": "MdStorage", "label": "Storage", "value": "Wardrobe, Bedside Table" }
      ],
      "bathroom": [
        { "icon": "FaBath", "label": "Space", "value": "2 Washrooms" },
        { "icon": "FaBath", "label": "Quality", "value": "High-end Fixtures and Fittings" },
        { "icon": "MdStorage", "label": "Storage", "value": "Cabinets for Toiletries" }
      ],
      "additionalFeatures": [
        { "icon": "FaCar", "label": "Parking", "value": "Personal Parking Available" },
        { "icon": "FaEye", "label": "Windows", "value": "UPVC Fenesta Windows with Toughened Glass" },
        { "icon": "FaHome", "label": "Flooring", "value": "Vitrified Tiles and Wooden Flooring" }
      ]
    },
    floorPlan:[
      "https://d2ehq5aws28ia0.cloudfront.net/floorPlan/plot01b.png",

    ],
    "floorPlanPdf": "https://d2ehq5aws28ia0.cloudfront.net/pdf2/SITE+B+PLOT+1.pdf"
  },
  "Plot_02":{
    "title": "2BHK Apartment: Cozy Mountain Escape",
    "plot": "Plot No - 02 (B)",
    "price": "55 Lacs",
    alt:"Home in Uttrakhand",
    yt:"https://www.youtube.com/embed/6iOp7AhhsTc",
    "images": [
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot02/4.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot02/3.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot02/1.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot02/2.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot02/5.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot02/6.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot02/7.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot02/8.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot02/9.jpg",
    ],
    "features": {
      "keyFeatures": [
        { "icon": "FaHome", "label": "Super Built-up Area", "value": "1340 sq ft" },
        { "icon": "FaMountain", "label": "Land Area", "value": "170 sq yard" },
        { "icon": "FaCouch", "label": "Furnishings", "value": "Optional" },
        { "icon": "FaEye", "label": "View", "value": "Bay Windows with Nanda Devi Himalayan View" },
        { "icon": "FaFire", "label": "Cozy Fireplace", "value": "Added Warmth and Ambiance" }
      ],
      "livingAreaKitchen": [
        { "icon": "FaCouch", "label": "Seating", "value": "Dining Table, Sofa" },
        { "icon": "FaTv", "label": "Entertainment", "value": "LCD Unit" },
        { "icon": "FaHome", "label": "Design", "value": "Integrated Modular Kitchen" },
        { "icon": "FaFire", "label": "Ventilation", "value": "Chimney" },
        { "icon": "FaWater", "label": "Hot Water", "value": "Geyser" },
        { "icon": "FaWind", "label": "Air Circulation", "value": "Exhaust Fan" },
        { "icon": "FaTint", "label": "Additional", "value": "Water Filter" }
      ],
      "bedroom": [
        { "icon": "FaBed", "label": "Furnishing", "value": "King Size Beds" },
        { "icon": "FaHome", "label": "Space", "value": "2 Bedrooms with Attached Baths" },
        { "icon": "FaEye", "label": "View", "value": "Bay Windows in Bedroom with Nanda Devi Himalayan View" },
        { "icon": "MdStorage", "label": "Storage", "value": "Wardrobe, Bedside Table" }
      ],
      "bathroom": [
        { "icon": "FaBath", "label": "Space", "value": "2 Washrooms" },
        { "icon": "FaBath", "label": "Quality", "value": "High-end Fixtures and Fittings" },
        { "icon": "MdStorage", "label": "Storage", "value": "Cabinets for Toiletries" }
      ],
      "additionalFeatures": [
        { "icon": "FaCar", "label": "Parking", "value": "Personal Parking Available" },
        { "icon": "FaEye", "label": "Windows", "value": "UPVC Fenesta Windows with Toughened Glass" },
        { "icon": "FaHome", "label": "Flooring", "value": "Vitrified Tiles and Wooden Flooring" }
      ]
    },
    floorPlan:[
      "https://d2ehq5aws28ia0.cloudfront.net/floorPlan/plot01b.png",

    ],
    "floorPlanPdf": "https://d2ehq5aws28ia0.cloudfront.net/pdf2/SITE+B+PLOT+2.pdf"
  },
  "Plot_27":{
    "title": "3BHK: Hilltop Getaway",
    "plot": "Plot No - 27",
    alt:"Villa in mukteshwar",
    "price": "85 Lacs",
    meta:{
      title:"Get 3 BHK cottage at Indus Valley Mukteshwar",
      des:"3 BHK cottage in Mukteshwar offers a prime location with scenic views. Ideal for residential development or investment at Indus Valley Mukteshwar"
    },
    yt:"https://www.youtube.com/embed/-cpH_3dkQKU",
    "images": [
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot27/1.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot27/2.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot27/3.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot27/4.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot27/6.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot27/7.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot27/5.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot27/8.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot27/9.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot27/10.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot27/11.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot27/12.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot27/13.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot27/14.jpg",
    ],
    "features": {
      "keyFeatures": [
        { "icon": "FaHome", "label": "Super Built-up Area", "value": "1750 sq ft" },
        { "icon": "FaMountain", "label": "Land Area", "value": "250 sq yard" },
        { "icon": "FaCouch", "label": "Furnishing", "value": "Semi Furnished Cottage" },
        { "icon": "FaEye", "label": "View", "value": "Bay Windows with Nanda Devi Himalayan View" },
        { "icon": "FaFire", "label": "Cozy Fireplace", "value": "For Added Warmth and Ambiance" },
        { "icon": "FaRulerHorizontal", "label": "Flooring", "value": "Wooden Flooring" }
      ],
      "livingAreaKitchen": [
        { "icon": "FaCouch", "label": "Seating", "value": "Dining Table, Sofa" },
        { "icon": "FaTv", "label": "Entertainment", "value": "LCD Unit" },
        { "icon": "FaHome", "label": "Design", "value": "Integrated Modular Kitchen" },
        { "icon": "FaFire", "label": "Ventilation", "value": "Chimney" },
        { "icon": "FaWater", "label": "Hot Water", "value": "Geyser" },
        { "icon": "FaWind", "label": "Air Circulation", "value": "Exhaust Fan" },
        { "icon": "FaTint", "label": "Additional", "value": "Water Filter" }
      ],
      "bedroom": [
        { "icon": "FaBed", "label": "Furnishing", "value": "King Size Beds" },
        { "icon": "FaHome", "label": "Space", "value": "3 Bedrooms with Attached Baths" },
        { "icon": "FaEye", "label": "View", "value": "Bay Windows in Bedrooms with Nanda Devi Himalayan View" },
        { "icon": "MdStorage", "label": "Storage", "value": "Wardrobe, Bedside Table" }
      ],
      "bathroom": [
        { "icon": "FaBath", "label": "Space", "value": "3 Washrooms" },
        { "icon": "FaBath", "label": "Quality", "value": "High-end Fixtures and Fittings" },
        { "icon": "MdStorage", "label": "Storage", "value": "Cabinets for Toiletries" }
      ],
      "additionalFeatures": [
        { "icon": "FaCar", "label": "Parking", "value": "Personal Parking Available" },
        { "icon": "FaEye", "label": "Windows", "value": "UPVC Fenesta Windows with Toughened Glass" },
        { "icon": "FaRulerHorizontal", "label": "Flooring", "value": "Vitrified Tiles and Wooden Flooring" }
      ]
    },
    floorPlan:[
      "https://d2ehq5aws28ia0.cloudfront.net/floorPlan/Plot_27floor1.jpeg",
      "https://d2ehq5aws28ia0.cloudfront.net/floorPlan/Plot_27floor2.png"
    ],
    "floorPlanPdf": "https://d2ehq5aws28ia0.cloudfront.net/pdf2/SITE+A+PLOT+27.pdf"
  },
  "Cottage_4bhk":{
    "title": "4BHK: The Himalayan View Cottage",
    "plot": "Plot No - 43",
    alt:"Himalayan View Cottage in Uttrakhand",
    yt:"https://www.youtube.com/embed/RLcDrfN89Qw?start=3",
    "price": "1.2 Cr",
    meta:{
      title:"Luxury 4 BHK Cottage for Sale at Indus Valley Mukteshwar",
      des:"Find your dream 4 BHK cottage for sale at Indus Valley Mukteshwar – spacious, well-designed homes offering a blend of luxury and nature"
    },
    "images": [
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/1.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/6.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/5.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/2.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/4.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/3.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/12.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/14.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/15.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/16.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/7.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/8.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/9.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/10.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/11.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/13.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot43/17.jpg",
    ],
    "features": {
      "keyFeatures": [
        { "icon": "FaMountain", "label": "Land Area", "value": "418 sq yard" },
        { "icon": "FaHome", "label": "Super Built-up Area", "value": "2650 sq ft" },
        { "icon": "FaCouch", "label": "Furnishing", "value": "Fully Furnished Cottage" },
        { "icon": "FaEye", "label": "View", "value": "Bay Windows with Nanda Devi Himalayan View" },
        { "icon": "FaFire", "label": "Cozy Fireplace", "value": "For Added Warmth and Ambiance" },
        { "icon": "FaRulerHorizontal", "label": "Flooring", "value": "Wooden Flooring" }
      ],
      "livingAreaKitchen": [
        { "icon": "FaCouch", "label": "Seating", "value": "Dining Table, Sofa, Coffee Table" },
        { "icon": "FaTv", "label": "Entertainment", "value": "LCD Unit" },
        { "icon": "FaHome", "label": "Design", "value": "Integrated Modular Kitchen" },
        { "icon": "FaFire", "label": "Ventilation", "value": "Chimney" },
        { "icon": "FaWater", "label": "Hot Water", "value": "Geyser" },
        { "icon": "FaWind", "label": "Air Circulation", "value": "Exhaust Fan" }
      ],
      "bedroom": [
        { "icon": "FaBed", "label": "Furnishing", "value": "King Size Beds" },
        { "icon": "FaHome", "label": "Space", "value": "4 Bedrooms with Attached Baths" },
        { "icon": "FaEye", "label": "View", "value": "Bay Windows in Bedrooms with Nanda Devi Himalayan View" },
        { "icon": "MdStorage", "label": "Storage", "value": "Wardrobe, Bedside Table" }
      ],
      "bathroom": [
        { "icon": "FaBath", "label": "Space", "value": "4 Attached Washrooms, 1 Common Washroom" },
        { "icon": "FaBath", "label": "Quality", "value": "High-end Fixtures and Fittings" },
        { "icon": "MdStorage", "label": "Storage", "value": "Cabinets for Toiletries" }
      ],
      "additionalFeatures": [
        { "icon": "FaCar", "label": "Parking", "value": "2 Personal Car Parking" },
        { "icon": "FaHome", "label": "Servant Room", "value": "Additional Servant Room" },
        { "icon": "FaTree", "label": "Garden", "value": "Private Garden with Swing Set (30% Land Area)" },
        { "icon": "FaChair", "label": "Balcony", "value": "Twin Swing Set and Table-Chair Set" },
        { "icon": "FaEye", "label": "Windows", "value": "UPVC Fenesta with Toughened Glass" }
      ]
    },
    floorPlan:[
      "https://d2ehq5aws28ia0.cloudfront.net/floorPlan/4bhkfloor1.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/floorPlan/4bhkfloor2.jpg"
    ],
    "floorPlanPdf": "https://d2ehq5aws28ia0.cloudfront.net/pdf2/SITE+A+PLOT+43.pdf"
  },
  "Plot_5_6":{
    images:[
      "https://indusvalleymukteshwar.com/static/media/ov4.f4b08c4c73b7999f412b.webp",
      "https://indusvalleymukteshwar.com/static/media/ov2.d1464fe51e9f7d16ddac.webp",
      "https://indusvalleymukteshwar.com/static/media/ov3.2c8897d5005c83a9cba4.webp",
      "https://indusvalleymukteshwar.com/static/media/ov4.f4b08c4c73b7999f412b.webp",
      "https://indusvalleymukteshwar.com/static/media/iv3.2b02244b7c64be1e0805.webp",
      "https://indusvalleymukteshwar.com/static/media/iv2.766cad47eae285ba73dd.webp",
      "https://indusvalleymukteshwar.com/static/media/iv3.2b02244b7c64be1e0805.webp",
      "https://indusvalleymukteshwar.com/static/media/iv4.0688f4911dfb8732f8f0.webp"
    ],
    floorPlan:[
      "https://indusvalleymukteshwar.com/static/media/3D_PLAN.51be1fec1af62310ba58.webp",
    ],
    "floorPlanPdf": "https://indusvalleymukteshwar.com/static/media/pdf_5_6.abff944dc1851a9ae0f4.pdf"
  
  },
  "Plot_26":{
    "title": "Upcoming: 1BHK Apartment",
    "overview": [
      "Super Buildup area 620 sq.ft.",
      "Bedroom with attached Washroom",
      "Drawing with Dining Area",
      "Balcony",
      "Floor Modular Kitchen",
      "Entrance Porch",
      "Elevator Access",
      "Personal Car Parking",
      "Fully Furnished",
      "Complete privacy",
      "Wide front view",
      "Large Open Common Garden Space"
    ],
    alt:"",
    images:[
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot26/1.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot26/2.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot26/3.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot26/4.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot26/5.jpg",

      ],
    floorPlan:[
      "https://d2ehq5aws28ia0.cloudfront.net/floorPlan/plan26.jpg"
    ],
    "floorPlanPdf": "https://d2ehq5aws28ia0.cloudfront.net/pdf2/SITE+A+PLOT+26.pdf"
  },
  
  "Plot_36":{
    "title": "Upcoming: 2BHK Apartment",
    overview:
      [
        "SUPER BUILT UP AREA : 845 sq.ft.",
        "2 Bedroom with attached Washrooms",
        "Both floor Modular Kitchen",
         "Drawing with Dining Area",
         "Balcony",
         "Entrance Lobby",
        "Personal Cars Parking",
        "Fully Furnished",
        "Wide Front View",
        "Panoromic view of Himalaya through Bay Windows",
        "Large Open Common Garden Space"
      ],
    alt:"",
    images:[
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot36/1.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot36/2.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot36/3.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot36/4.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot36/5.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/plot36/6.jpg",
    ],
      floorPlan:[
        "https://d2ehq5aws28ia0.cloudfront.net/floorPlan/plot36.jpg"
      ],
      floorPlanPdf:"https://d2ehq5aws28ia0.cloudfront.net/pdf2/SITE+A+PLOT+36.pdf"
  },
  "1bhk_simplex_duplex":{
    "title": "Upcoming: 1BHK Cottage Simplex/Duplex",
    overview:
      [
        "SUPER BUILT UP AREA : 700 sq.ft.",
        "Plot Land Area 125 sq. yards",
        "Bedroom with attached Washroom",
        "Floor Modular Kitchen",
        "Drawing with Dining Area",
        "Personal Cars Parking",
        "Fully Furnished Optional",
        "Wide Front View",
        "Panoromic view of Himalaya through Bay Windows",
        "Large Open Common Garden Space"
      ],
    images:[
      "https://d2ehq5aws28ia0.cloudfront.net/plots/1bhksimplex/1.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/1bhksimplex/2.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/1bhksimplex/7.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/1bhksimplex/4.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/1bhksimplex/5.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/1bhksimplex/3.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/1bhksimplex/6.jpg",
    ],
    alt:"",
      floorPlan:[
        "https://d2ehq5aws28ia0.cloudfront.net/floorPlan/1bhksimplex.png",
        "https://d2ehq5aws28ia0.cloudfront.net/floorPlan/1bhksimplex2.jpg",
      ],
      floorPlanPdf:"https://d2ehq5aws28ia0.cloudfront.net/pdf2/1BHK+Cottage+(Simplex%2CDouplex).pdf"
  },
  "2bhk_duplex":{
    "title": "Upcoming: 2BHK Cottage Simplex/Duplex",
    alt:"",
    overview:
      [
        "SUPER BUILT UP AREA : 850 sq.ft.",
        "Plot Land Area 125 sq. yards",
        "2 Bedrooms with attached Washrooms",
        "Floor Modular Kitchen",
        "Drawing with Dining Area",
        "Personal Cars Parking",
        "Fully Furnished Optional",
        "Wide Front View",
        "Panoromic view of Himalaya through Bay Windows",
        "Large Open Common Garden Space"
      ],
    images:[
      "https://d2ehq5aws28ia0.cloudfront.net/plots/2bhksimplex/1.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/2bhksimplex/2.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/2bhksimplex/3.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/2bhksimplex/4.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/2bhksimplex/5.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/2bhksimplex/6.jpg",
    ],
      floorPlan:[
        "https://d2ehq5aws28ia0.cloudfront.net/floorPlan/2bhksimplex2.png",
        "https://d2ehq5aws28ia0.cloudfront.net/floorPlan/2bhksimplex.jpg",
      ],
      floorPlanPdf:"https://d2ehq5aws28ia0.cloudfront.net/pdf2/2+BHK+Cottage+(simplex%2CDouplex).pdf"
  },
  Wooden_2bhk:{
    plot: "Plot No - 65",
    "title": "Premium 2BHK Wooden Cottages",
    images:[
      "https://d2ehq5aws28ia0.cloudfront.net/plots/woodensimplex/1.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/woodensimplex/2.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/woodensimplex/3.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/woodensimplex/4.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/woodensimplex/5.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/woodensimplex/6.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/woodensimplex/7.jpg",

    ],
    "features": {
      "keyFeatures": [
        { "icon": "FaMountain", "label": "Land Area", "value": "250 sq yard" },
        { "icon": "FaHome", "label": "Super Built-up Area", "value": "1114 sq ft" },
        { "icon": "FaCouch", "label": "Furnishing", "value": "Fully Furnished Cottage" },
        { "icon": "FaEye", "label": "View", "value": "Bay Windows with Nanda Devi Himalayan View" },
        { "icon": "FaFire", "label": "Cozy Fireplace", "value": "For Added Warmth and Ambiance" },
        { "icon": "FaRulerHorizontal", "label": "Material", "value": "Pine Wood" }
      ],
      "livingAreaKitchen": [
        { "icon": "FaCouch", "label": "Seating", "value": "Dining Table, Sofa, Coffee Table" },
        { "icon": "FaTv", "label": "Entertainment", "value": "LCD Unit" },
        { "icon": "FaHome", "label": "Design", "value": "Integrated Modular Kitchen" },
        { "icon": "FaFire", "label": "Ventilation", "value": "Chimney" },
        { "icon": "FaWater", "label": "Hot Water", "value": "Geyser" },
        { "icon": "FaWind", "label": "Air Circulation", "value": "Exhaust Fan" }
      ],
      "bedroom": [
        { "icon": "FaBed", "label": "Furnishing", "value": "King Size Beds" },
        { "icon": "FaHome", "label": "Space", "value": "2 Bedrooms with Attached Baths" },
        { "icon": "FaEye", "label": "View", "value": "Bay Windows in Bedrooms with Nanda Devi Himalayan View" },
        { "icon": "MdStorage", "label": "Storage", "value": "Wardrobe, Bedside Table" }
      ],
      "bathroom": [
        { "icon": "FaBath", "label": "Space", "value": "2 Attached Washrooms" },
        { "icon": "FaBath", "label": "Quality", "value": "High-end Fixtures and Fittings" },
        { "icon": "MdStorage", "label": "Storage", "value": "Cabinets for Toiletries" }
      ],
      "additionalFeatures": [
        { "icon": "FaCar", "label": "Parking", "value": "Personal Car Parking" },
        { "icon": "FaTree", "label": "Garden", "value": "Private Garden with Swing Set" },
        { "icon": "FaEye", "label": "Windows", "value": "UPVC Fenesta with Toughened Glass" }
      ]
    },
    alt:"2bhk Simplex",
    yt:"https://www.youtube.com/embed/4FrFfX50UGc?si=kFi3-wiWhGGX5D1h",
    floorPlanPdf:"https://d2ehq5aws28ia0.cloudfront.net/pdf2/2+bhk+simplex.pdf",
    floorPlan:[
      "https://d2ehq5aws28ia0.cloudfront.net/plots/woodensimplex/2bhk+render.jpg",
    ],
  },

  Wooden_2bhkDuplex:{
    plot: "Plot No - 62",
    "title": "Premium 2BHK Duplex Wooden Cottages",
    images:[
      "https://d2ehq5aws28ia0.cloudfront.net/plots/2bhkduplex/1.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/2bhkduplex/2.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/2bhkduplex/3.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/2bhkduplex/4.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/2bhkduplex/5.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/2bhkduplex/6.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/2bhkduplex/7.jpg",

    ],
    "features": {
      "keyFeatures": [
        { "icon": "FaMountain", "label": "Land Area", "value": "250 sq yard" },
        { "icon": "FaHome", "label": "Super Built-up Area", "value": "1793 sq ft" },
        { "icon": "FaCouch", "label": "Furnishing", "value": "Fully Furnished Cottage" },
        { "icon": "FaEye", "label": "View", "value": "Bay Windows with Nanda Devi Himalayan View" },
        { "icon": "FaFire", "label": "Cozy Fireplace", "value": "For Added Warmth and Ambiance" },
        { "icon": "FaRulerHorizontal", "label": "Material", "value": "Pine Wood" }
      ],
      "livingAreaKitchen": [
        { "icon": "FaCouch", "label": "Seating", "value": "Dining Table, Sofa, Coffee Table" },
        { "icon": "FaTv", "label": "Entertainment", "value": "LCD Unit" },
        { "icon": "FaHome", "label": "Design", "value": "Integrated Modular Kitchen" },
        { "icon": "FaFire", "label": "Ventilation", "value": "Chimney" },
        { "icon": "FaWater", "label": "Hot Water", "value": "Geyser" },
        { "icon": "FaWind", "label": "Air Circulation", "value": "Exhaust Fan" }
      ],
      "bedroom": [
        { "icon": "FaBed", "label": "Furnishing", "value": "King Size Beds" },
        { "icon": "FaHome", "label": "Space", "value": "2 Bedrooms with Attached Baths" },
        { "icon": "FaEye", "label": "View", "value": "Bay Windows in Bedrooms with Nanda Devi Himalayan View" },
        { "icon": "MdStorage", "label": "Storage", "value": "Wardrobe, Bedside Table" }
      ],
      "bathroom": [
        { "icon": "FaBath", "label": "Space", "value": "2 Attached Washrooms" },
        { "icon": "FaBath", "label": "Quality", "value": "High-end Fixtures and Fittings" },
        { "icon": "MdStorage", "label": "Storage", "value": "Cabinets for Toiletries" }
      ],
      "additionalFeatures": [
        { "icon": "FaCar", "label": "Parking", "value": "Personal Car Parking" },
        { "icon": "FaTree", "label": "Garden", "value": "Private Garden with Swing Set" },
        { "icon": "FaEye", "label": "Windows", "value": "UPVC Fenesta with Toughened Glass" },
        { "icon": "FaHome", "label": "Servant Room", "value": "Additional Servant Room" },
      ]
    },
    alt:"2bhk Duplex in Uttarakhand",
    yt:"https://www.youtube.com/embed/4FrFfX50UGc?si=kFi3-wiWhGGX5D1h",
    floorPlanPdf:"https://d2ehq5aws28ia0.cloudfront.net/pdf2/2+bhk+duplex+wooden+cottages.pdf",
    floorPlan:[
      "https://d2ehq5aws28ia0.cloudfront.net/plots/2bhkduplex/2bhkduplex.png",
    ],
  },
  Wooden_3bhk:{
    plot: "Plot No - 64",
    "title": "Premium 3BHK Wooden Cottages",
    images:[
      "https://d2ehq5aws28ia0.cloudfront.net/plots/3bhkwooden/1.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/3bhkwooden/2.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/3bhkwooden/3.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/3bhkwooden/4.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/3bhkwooden/5.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/3bhkwooden/6.jpg",

    ],
    "features": {
      "keyFeatures": [
        { "icon": "FaMountain", "label": "Land Area", "value": "300 sq yard" },
        { "icon": "FaHome", "label": "Super Built-up Area", "value": "2018 sq ft" },
        { "icon": "FaCouch", "label": "Furnishing", "value": "Fully Furnished Cottage" },
        { "icon": "FaEye", "label": "View", "value": "Bay Windows with Nanda Devi Himalayan View" },
        { "icon": "FaFire", "label": "Cozy Fireplace", "value": "For Added Warmth and Ambiance" },
        { "icon": "FaRulerHorizontal", "label": "Material", "value": "Pine Wood" }
      ],
      "livingAreaKitchen": [
        { "icon": "FaCouch", "label": "Seating", "value": "Dining Table, Sofa, Coffee Table" },
        { "icon": "FaTv", "label": "Entertainment", "value": "LCD Unit" },
        { "icon": "FaHome", "label": "Design", "value": "Integrated Modular Kitchen" },
        { "icon": "FaFire", "label": "Ventilation", "value": "Chimney" },
        { "icon": "FaWater", "label": "Hot Water", "value": "Geyser" },
        { "icon": "FaWind", "label": "Air Circulation", "value": "Exhaust Fan" }
      ],
      "bedroom": [
        { "icon": "FaBed", "label": "Furnishing", "value": "King Size Beds" },
        { "icon": "FaHome", "label": "Space", "value": "3 Bedrooms with Attached Baths" },
        { "icon": "FaEye", "label": "View", "value": "Bay Windows in Bedrooms with Nanda Devi Himalayan View" },
        { "icon": "MdStorage", "label": "Storage", "value": "Wardrobe, Bedside Table" },
      ],
      "bathroom": [
        { "icon": "FaBath", "label": "Space", "value": "3 Attached Washrooms" },
        { "icon": "FaBath", "label": "Quality", "value": "High-end Fixtures and Fittings" },
        { "icon": "MdStorage", "label": "Storage", "value": "Cabinets for Toiletries" }
      ],
      "additionalFeatures": [
        { "icon": "FaCar", "label": "Parking", "value": "Personal Car Parking" },
        { "icon": "FaTree", "label": "Garden", "value": "Private Garden with Swing Set" },
        { "icon": "FaEye", "label": "Windows", "value": "UPVC Fenesta with Toughened Glass" },
        { "icon": "FaHome", "label": "Servant Room", "value": "Additional Servant Room" },
        { "icon": "FaChair", "label": "Balcony", "value": "Twin Swing Set and Table-Chair Set" },
      ]
    },
    alt:"3BHK In Uttarakhand",
    yt:"https://www.youtube.com/embed/4FrFfX50UGc?si=kFi3-wiWhGGX5D1h",
    floorPlanPdf:"https://d2ehq5aws28ia0.cloudfront.net/pdf2/3+bhk+wooden+cottages+300+sq.yard.pdf",
    floorPlan:[
      "https://d2ehq5aws28ia0.cloudfront.net/plots/3bhkwooden/3bhkWooden.png",
    ],
  },
  Wooden_3bhk250:{
    plot: "Plot No - 63",
    "title": "Premium 3BHK Wooden Cottages",
    images:[
      "https://d2ehq5aws28ia0.cloudfront.net/plots/3bhkwooden250/1.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/3bhkwooden250/2.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/3bhkwooden250/3.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/3bhkwooden250/4.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/3bhkwooden250/5.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/3bhkwooden250/6.jpg",
      "https://d2ehq5aws28ia0.cloudfront.net/plots/3bhkwooden250/7.jpg",

    ],
    "features": {
      "keyFeatures": [
        { "icon": "FaMountain", "label": "Land Area", "value": "250 sq yard" },
        { "icon": "FaHome", "label": "Super Built-up Area", "value": "1917 sq ft" },
        { "icon": "FaCouch", "label": "Furnishing", "value": "Fully Furnished Cottage" },
        { "icon": "FaEye", "label": "View", "value": "Bay Windows with Nanda Devi Himalayan View" },
        { "icon": "FaFire", "label": "Cozy Fireplace", "value": "For Added Warmth and Ambiance" },
        { "icon": "FaRulerHorizontal", "label": "Material", "value": "Pine Wood" }
      ],
      "livingAreaKitchen": [
        { "icon": "FaCouch", "label": "Seating", "value": "Dining Table, Sofa, Coffee Table" },
        { "icon": "FaTv", "label": "Entertainment", "value": "LCD Unit" },
        { "icon": "FaHome", "label": "Design", "value": "Integrated Modular Kitchen" },
        { "icon": "FaFire", "label": "Ventilation", "value": "Chimney" },
        { "icon": "FaWater", "label": "Hot Water", "value": "Geyser" },
        { "icon": "FaWind", "label": "Air Circulation", "value": "Exhaust Fan" }
      ],
      "bedroom": [
        { "icon": "FaBed", "label": "Furnishing", "value": "King Size Beds" },
        { "icon": "FaHome", "label": "Space", "value": "3 Bedrooms with Attached Baths" },
        { "icon": "FaEye", "label": "View", "value": "Bay Windows in Bedrooms with Nanda Devi Himalayan View" },
        { "icon": "MdStorage", "label": "Storage", "value": "Wardrobe, Bedside Table" },
      ],
      "bathroom": [
        { "icon": "FaBath", "label": "Space", "value": "3 Attached Washrooms" },
        { "icon": "FaBath", "label": "Quality", "value": "High-end Fixtures and Fittings" },
        { "icon": "MdStorage", "label": "Storage", "value": "Cabinets for Toiletries" }
      ],
      "additionalFeatures": [
        { "icon": "FaCar", "label": "Parking", "value": "Personal Car Parking" },
        { "icon": "FaTree", "label": "Garden", "value": "Private Garden with Swing Set" },
        { "icon": "FaEye", "label": "Windows", "value": "UPVC Fenesta with Toughened Glass" },
        { "icon": "FaHome", "label": "Servant Room", "value": "Additional Servant Room" },
        { "icon": "FaChair", "label": "Balcony", "value": "Twin Swing Set and Table-Chair Set" },
      ]
    },
    alt:"3bhk Cottage In MUkteshwar",
    yt:"https://www.youtube.com/embed/4FrFfX50UGc?si=kFi3-wiWhGGX5D1h",
    floorPlanPdf:"https://d2ehq5aws28ia0.cloudfront.net/pdf2/3+bhk+wooden+cottages+250+sq.yard.pdf",
    floorPlan:[
      "https://d2ehq5aws28ia0.cloudfront.net/plots/3bhkwooden250/3+bhk+250+sq+yard.png",
    ],
  }
  
  };