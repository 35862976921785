import Counter from "../newComponents/Counter";
import Enquiryform from "../newComponents/Enquiryform";
import Projectreview from "../newComponents/Projectreview";
import RouteMap from "../newComponents/RouteMap";
import SiteLayout from "../newComponents/SiteLayout";
import VideoBanner from "../newComponents/VideoBanner";
import Testimonials from "../newComponents/Testimonials";
import { Helmet } from "react-helmet";
import Textanimation from "../newComponents/Textanimation";

export default function Home() {

  return (
    <>
      <Helmet>
        <title>Indus Valley Mukteshwar| Luxury Cottages In Mukteshwar Uttarakhand</title>
        <meta
          name="description"
          content="We are selling property in Mukteshwar, which include studio apartments, cottages in Mukteshwar and flat in Uttarakhand. We sell the best cottages near Nainital."
        />
        <script defer type="application/ld+json">{`
    {
      "@context": "https://schema.org",
      "@type": "LocalBusiness",
      "name": "Indus Valley Mukteshwar",
      "image": "https://www.indusvalleymukteshwar.com/static/media/navlogo.50214603480cefd7a8b2.png",
      "@id": "",
      "url": "https://www.indusvalleymukteshwar.com/",
      "telephone": "+91 8510850101",
      "priceRange": "$$",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "Dhanachuli Bend, Village - Sunderkhal",
        "addressLocality": "Mukteshwar",
        "postalCode": "263132",
        "addressCountry": "IN"
      },
      "geo": {
        "@type": "GeoCoordinates",
        "latitude": 29.400333282995806,
        "longitude": 79.67433379194084
      },
      "openingHoursSpecification": {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday"
        ],
        "opens": "10:00",
        "closes": "06:00"
      },
      "sameAs": [
        "https://www.facebook.com/IndusValleyMukteshwar",
        "https://www.instagram.com/indusvalley.mukteshwar",
        "https://www.youtube.com/@indusvalleymukteshwar"
      ]
    }`}
        </script>
      </Helmet>
      <VideoBanner />
      {/* <div id="resize-effect" className="flex text-center justify-center w-full mt-8 text-green-700 yeseva">
        <span aria-hidden="true" className="text-center">
          <span className="text-center">Homes That Breathe With Nature</span>
        </span>
      </div> */}
          <Textanimation/>
      <Projectreview />
      <SiteLayout />
      <Counter />
      <RouteMap />
      <Enquiryform />
      <Testimonials />
    </>
  )
}