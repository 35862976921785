import React, { useState } from 'react';
import axios from 'axios';
import Google_Map2 from '../components/Google_Map2';
import bottomline from './asset/bottomline.png';

function Enquiryform() {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseMessage, setResponseMessage] = useState(true);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setResponseMessage('');

    try {
      const response = await axios.post(
        'https://account.solidperformers.com/leadsapi/data/aaff8b2fe67f5b345a80b5b6c95acbe8',
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        setResponseMessage(false);
        setFormData({ name: '', phone: '' });
      } else {
        setResponseMessage('Error: Failed to submit the form.');
      }
    } catch (error) {
      setResponseMessage('An error occurred. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="w-full gap-5 flex flex-col justify-between items-center mt-8 p-5">
      <div className="flex flex-col justify-center items-center">
        <p className="text-3xl lg:text-4xl font-bold text-center poppins text-green-700 uppercase tracking-wider yeseva">
          Connect with Mother Nature
        </p>
        <img className="ml-[-10px] mb-3 md:block hidden" src={bottomline} alt="" />
        <img className="ml-[-10px] mb-3 md:hidden block w-[300px]" src={bottomline} alt="" />
      </div>
      <div className="w-full flex flex-col lg:flex-row items-center justify-center lg:gap-3 gap-8">
        {/* Google Map Section */}
        <div className="w-full lg:w-[40%]">
          <div className="w-auto h-[50vh]">
            <Google_Map2 />
          </div>
        </div>

        {/* Divider Line */}
        <div className="hidden lg:block w-[100px] h-[80%] bg-gray-300"></div>

        {/* Enquiry Form Section */}
        <div className="w-full lg:w-[auto] flex items-center justify-center">
        {/* {!responseMessage ?  */}
        {responseMessage  ? 
        <form
            onSubmit={handleSubmit}
            className="mx-auto w-full flex flex-col justify-center items-center"
          >
            <p className="text-center text-green-700 text-5xl my-3 font-bold falija uppercase">
              Indus Valley
            </p>

            <div className="flex gap-5 w-full lg:w-[500px] flex-col items-center">
              <label className="flex w-full items-center lg:items-start flex-col text-xl text-gray-600 gap-2 poppins font-semibold">
                <input
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="border-b-2 border-green-700 bg-transparent text-left w-[70%] lg:w-full focus:none outline-none px-5 py-3"
                  placeholder="Name"
                  type="text"
                  required
                />
              </label>

              <label className="flex w-full items-center lg:items-start flex-col text-xl text-gray-600 gap-2 poppins font-semibold">
                <input
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="border-b-2 border-green-700 bg-transparent text-left w-[70%] lg:w-full focus:none outline-none px-5 py-3"
                  placeholder="Phone"
                  type="tel"
                  required
                  pattern="[0-9]{10}"
                  maxLength="10"
                  minLength="10"
                />
              </label>
            </div>
            <button
              type="submit"
              className="mt-8 bg-green-700 px-5 py-3 border-2 border-green-700 rounded-2xl hover:bg-transparent hover:text-green-700 text-white font-bold transition ease-in-out delay-150 duration-150"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
          </form> :
            
              // <p className="mt-4 text-center text-sm text-gray-600">{responseMessage}</p>
              <div className='mx-auto w-full flex flex-col justify-center items-center'>
                  <p className='text-2xl lg:text-3xl text-center yeseva text-green-700'>Thanks For Submitting</p>
                  <p className='text-md poppins mt-5 text-center '>Thank you for reaching out! <br/>We’ve received your query and will get in touch <br/>with you shortly.</p>
                  <p className='text-md poppins mt-2 text-center '>For immediate assistance,</p>
                  <p className='text-md poppins text-center '>feel free to call us at</p>
                <a className="text-zinc-700 text-xl mt-2 hover:text-green-500" href="tel:8510850101">8510 850 101</a>
              </div>
            }
        </div>
      </div>
    </div>
  );
}

export default Enquiryform;
