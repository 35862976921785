import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import bottomline from './asset/bottomline.png';


const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const videos = [
    "lxsUgVAG5DU",
    "JJ4YaA4K2dg",
    "3VCF5F7AUx4",
    "ZPsYsz52Hms?start=2"
  ];

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (currentIndex < videos.length - 1) {
        setCurrentIndex(prev => prev + 1);
      }
    },
    onSwipedRight: () => {
      if (currentIndex > 0) {
        setCurrentIndex(prev => prev - 1);
      }
    },
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true
  });

  return (
    <div className="w-full bg-cover lg:bg-opacity-80">
      <div className="flex flex-col mt-12 justify-center items-center">
        <p className="text-3xl lg:text-4xl font-bold text-center poppins text-green-700 uppercase tracking-wider yeseva">
          Testimonials
        </p>
        <img className="ml-[-10px] mb-3 md:block hidden" src={bottomline} alt="" />
        <img className="ml-[-10px] mb-3 md:hidden block w-[300px]" src={bottomline} alt="" />
      </div>

      {/* Mobile Carousel */}
      <div className="lg:hidden relative w-full" {...handlers}>
        <div className="w-full overflow-hidden">
          <div 
            className="flex transition-transform duration-300 ease-out"
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {videos.map((videoId, index) => (
              <div key={index} className="w-full flex-shrink-0 px-4">
                <section className="text-center mx-auto relative">
                  <div className="absolute inset-0 z-10" />
                  <iframe
                    src={`https://www.youtube.com/embed/${videoId}`}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    height={200}
                    width="100%"
                    title="YouTube video player"
                    className="max-w-[300px] mx-auto relative"
                  ></iframe>
                </section>
              </div>
            ))}
          </div>
        </div>

        {/* Progress Dots */}
        <div className="flex justify-center gap-2 mt-4">
          {videos.map((_, index) => (
            <div
              key={index}
              className={`h-2 w-2 rounded-full transition-all duration-300 ${
                currentIndex === index 
                  ? 'bg-green-700 w-6' 
                  : 'bg-gray-300'
              }`}
            />
          ))}
        </div>
      </div>

      {/* Desktop Grid */}
      <div className="hidden lg:block">
        <div className="flex gap-5 justify-center items-center container mx-auto">
          <div className="grid grid-cols-4 gap-4 m-auto">
            {videos.map((videoId, index) => (
              <section
                key={index}
                className="text-center mx-auto p-6 lg:py-10 font-glacial transform transition-transform duration-500 hover:scale-110"
              >
                <iframe
                  src={`https://www.youtube.com/embed/${videoId}`}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  height={200}
                  width={300}
                  title="YouTube video player"
                ></iframe>
              </section>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;