import React, { useState, useEffect } from "react";
import { FaChevronRight, FaDownload, FaCar, FaBed, FaBath, FaHome} from "react-icons/fa";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate, useParams } from 'react-router-dom';
import { Data } from "./data";
import Carousel from "./Carousel"
import bottomline from "../newComponents/asset/bottomline.png"
import { Helmet } from "react-helmet";
import NotFound from "../screens/NotFound";


 
function RenderComponent() {
  const {link} = useParams()
  const navigate = useNavigate()


  const [fullscreenImage, setFullscreenImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true)

  useEffect(()=>{
    setTimeout(()=>{
      setLoading(false)
    },1000)
  }, [])

  const openFullscreen = (image) => {
    setFullscreenImage(image);
  };

  const closeFullscreen = () => {
    setFullscreenImage(null);
  };

  const goBackToGallery = () => {
    if(currentIndex === 0 ){
      setCurrentIndex(apartmentData.floorPlan.length - 1)
    setFullscreenImage(apartmentData.floorPlan[currentIndex]);

    }
    else
    {
      setCurrentIndex(currentIndex - 1)
      setFullscreenImage(apartmentData.floorPlan[currentIndex]);

      }
  };

  const showNextImage = () => {
    if(currentIndex === apartmentData.floorPlan.length ){
      setCurrentIndex(0)
      setFullscreenImage(apartmentData.floorPlan[currentIndex]);

    }
    else
    {
      setCurrentIndex(currentIndex + 1)
      setFullscreenImage(apartmentData.floorPlan[currentIndex]);

      }
  };

  const handleButtonClick = () => {
    window.open("/contact", "_blank");
  };

  const openPdf1 = () => {
    window.open(apartmentData.floorPlanPdf, "_blank");
  };

const apartmentData = Data[link]

// useEffect(() => {
//   if (!apartmentData) {
//     navigate("/404", { replace: true });
//   }
// }, [apartmentData, navigate]);

// if (!apartmentData) return null;
console.log(" apartment data "+apartmentData)


   return (
    <>{
      apartmentData !== undefined ? <>
    <div className='h-[120px] w-full'>
      {/* Helmet for SEO */}
      {apartmentData.meta && 
      <Helmet>
        <title>{apartmentData.meta.title}</title>
        <meta
          name="description"
          content={apartmentData.meta.des}
        />
      </Helmet>}
      
      {/* <img className='h-full w-full object-cover' src="https://indusvalleymukteshwar.com/static/media/ov1.c7210f64efdcf5a67df9.webp" alt="" /> */}
    </div>
  
    <div className="flex flex-col items-center gap-6 bg-green-50 p-5">
      {apartmentData.title && 
        <h3 className="text-2xl md:text-3xl xl:text-4xl font-semibold text-center p-5">
          <div className='flex flex-col mb-5 mt-[40px] justify-center items-center'>
        <h1 className='text-3xl font-bold text-center poppins text-green-700 uppercase tracking-wider yeseva'>
        {apartmentData.title}
        </h1>
        <img className="ml-[-10px] mb-3 md:block hidden" src={bottomline} alt="" />
        <img className="ml-[-10px] mb-3 md:hidden block w-[300px]" src={bottomline} alt="" />
      </div>
          <br />
          {apartmentData.plot}
        </h3>
      }
  
      {apartmentData.images && <Carousel images={apartmentData.images} alt={apartmentData.alt}/>}
  
      {/* Conditionally render overview */}
      {apartmentData.overview && 
        <section className="w-full md:w-4/5 xl:w-3/4 p-5">
          <div className='flex flex-col mb-5 mt-[40px] justify-center items-center'>
        <h1 className='text-3xl font-bold text-center poppins text-green-700 uppercase tracking-wider yeseva'>
        OVERVIEW
        </h1>
        <img className="ml-[-10px] mb-3 md:block hidden" src={bottomline} alt="" />
        <img className="ml-[-10px] mb-3 md:hidden block w-[300px]" src={bottomline} alt="" />

      </div>
          <ul className="text-base md:text-lg xl:text-xl list-disc pl-5">
          {apartmentData.overview.map((point, index) => (
            <li key={index} className="mb-2">{point}</li>
          ))}
        </ul>
        </section>
      }
  
      {apartmentData.features && 
      <>
        <section className="w-full md:w-4/5 xl:w-3/4 p-5">
        <div className='flex flex-col mb-5 mt-[40px] justify-center items-center'>
        <h1 className='text-3xl font-bold text-center poppins text-green-700 uppercase tracking-wider yeseva'>
        KEY FEATURES
        </h1>
        <img className="ml-[-10px] mb-3 md:block hidden" src={bottomline} alt="" />
        <img className="ml-[-10px] mb-3 md:hidden block w-[300px]" src={bottomline} alt="" />

      </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
            {apartmentData.features.keyFeatures.map((feature, index) => (
              <div className="flex items-center gap-3 p-4 rounded-xl" key={index}>
                <FaHome className="text-2xl text-green-600" />
                <span className="font-semibold">
                  {feature.label}: {feature.value}
                </span>
              </div>
            ))}
          </div>
        </section>
  
        <section className="w-full md:w-4/5 xl:w-3/4 p-5">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
            <div className="p-6 rounded-xl">
              <h5 className="text-xl font-semibold mb-3">LIVING AREA / KITCHEN</h5>
              <ul className="list-none space-y-3">
                {apartmentData.features.livingAreaKitchen.map((item, index) => (
                  <li className="flex items-center gap-3" key={index}>
                    <FaBed className="text-green-600" /> {item.label}: {item.value}
                  </li>
                ))}
              </ul>
            </div>
  
            <div className="p-6 rounded-xl">
              <h5 className="text-xl font-semibold mb-3">BEDROOM</h5>
              <ul className="list-none space-y-3">
                {apartmentData.features.bedroom.map((item, index) => (
                  <li className="flex items-center gap-3" key={index}>
                    <FaBed className="text-green-600" /> {item.label}: {item.value}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
  
        <section className="w-full md:w-4/5 xl:w-3/4 p-5">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
            <div className="p-6 rounded-xl">
              <h5 className="text-xl font-semibold mb-3">BATHROOM</h5>
              <ul className="list-none space-y-3">
                {apartmentData.features.bathroom.map((item, index) => (
                  <li className="flex items-center gap-3" key={index}>
                    <FaBath className="text-green-600" /> {item.label}: {item.value}
                  </li>
                ))}
              </ul>
            </div>
  
            <div className="p-6 rounded-xl">
              <h4 className="text-xl font-semibold mb-3">ADDITIONAL FEATURES</h4>
              <ul className="list-none space-y-3">
                {apartmentData.features.additionalFeatures.map((item, index) => (
                  <li className="flex items-center gap-3" key={index}>
                    <FaCar className="text-green-600" /> {item.label}: {item.value}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
      </>}
  
      <section className="w-full md:w-4/5 xl:w-3/4 p-5 overflow-hidden">
      {apartmentData.floorPlan &&
      <>
        <div className='flex flex-col mb-5 mt-[40px] justify-center items-center'>
        <h1 className='text-3xl font-bold text-center poppins text-green-700 uppercase tracking-wider yeseva'>
        Floor Plan
        </h1>
        <img className="ml-[-10px] mb-3 md:block hidden" src={bottomline} alt="" />
        <img className="ml-[-10px] mb-3 md:hidden block w-[300px]" src={bottomline} alt="" />

      </div>
        
          { 
          loading ? 
          <>
          <div
          className={
            apartmentData.floorPlan.length > 1
              ? "grid grid-cols-1 md:grid-cols-2 gap-5 overflow-hidden"
              : "overflow-hidden flex justify-center items-center"
          }
        >
          {apartmentData.floorPlan.length > 1
            ? Array(2)
                .fill(null)
                .map((_, index) => (
                  <div
                    key={index}
                    className="h-[300px] bg-gray-300 shadow-md rounded-xl animate-pulse"
                  ></div>
                ))
            : (
              <div className="h-[300px] w-[80%] bg-gray-300 shadow-md rounded-xl animate-pulse"></div>
            )}
        </div>
</>        
          :
            <div className={apartmentData.floorPlan.length > 1 ? "grid grid-cols-1 md:grid-cols-2 gap-5 overflow-hidden" : " overflow-hidden flex justify-center items-center"}>
            {apartmentData.floorPlan.map((image, index) => (
              <img
                key={index}
                className="h-[300px] shadow-md rounded-xl object-contain cursor-pointer hover:scale-105 transition-all duration-300"
                src={image}
                alt={`Floor Plan ${index + 1}`}
                onClick={() => openFullscreen(image)}
              />
            ))}
          </div>
          }
          </>
        }
  
        {fullscreenImage && (
          <div
            className="fixed inset-0 z-[50] bg-black bg-opacity-80 flex justify-center items-center"
            onClick={closeFullscreen}
          >
            <button
              className="absolute top-[50%] left-5 text-white text-3xl"
              onClick={(e) => {
                e.stopPropagation();
                goBackToGallery();
              }}
            >
              <IoIosArrowBack />
            </button>
  
            <button
              className="absolute top-[50%] right-5 text-white text-3xl"
              onClick={(e) => {
                e.stopPropagation();
                showNextImage();
              }
              }
            >
              <FaChevronRight />
            </button>
  
            <img
              src={fullscreenImage}
              alt="Fullscreen View"
              className="max-w-full max-h-full p-4"
            />
          </div>
        )}
      </section>

      {apartmentData.sitePlan &&
        <>
        <div className='flex flex-col mb-5 mt-[40px] justify-center items-center'>
        <h1 className='text-3xl font-bold text-center poppins text-green-700 uppercase tracking-wider yeseva'>
        Site Plan
        </h1>
        <img className="ml-[-10px] mb-3 md:block hidden" src={bottomline} alt="" />
        <img className="ml-[-10px] mb-3 md:hidden block w-[300px]" src={bottomline} alt="" />

      </div>
          <div className={apartmentData.sitePlan.length > 1 ? "grid grid-cols-1 md:grid-cols-2 gap-5" : " grid grid-cols-1 gap-5"}>
            {apartmentData.sitePlan.map((image, index) => (
              <img
                key={index}
                className=" h-[500px] shadow-md rounded-xl cursor-pointer hover:scale-105 transition-all duration-300"
                src={image}
                alt={`Floor Plan ${index + 1}`}
                onClick={() => openFullscreen(image)}
              />
            ))}
          </div>
          </>
        }
  
      
      {apartmentData.yt && 
      <section className={apartmentData.title === "Premium Semiwood Cottages" 
        ? "p-3 flex flex-col justify-center items-center mt-[-180px] pb-5" 
        : "p-3 flex flex-col justify-center items-center mt-0 pb-5"} >
        <div className='flex flex-col mb-5 mt-[40px] justify-center items-center'>
        <h1 className='text-3xl font-bold text-center poppins text-green-700 uppercase tracking-wider yeseva'>
        Youtube Link
        </h1>
        <img className="ml-[-10px] mb-3 md:block hidden" src={bottomline} alt="" />
        <img className="ml-[-10px] mb-3 md:hidden block w-[300px]" src={bottomline} alt="" />

      </div>
        <div className="video-container">
          <iframe
          
            className="w-[500px] aspect-video md:w-[800px] "
            src={apartmentData.yt}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </section>
      }
  
      <section className="p-5 flex justify-center gap-5">
        <button
          className="bg-green-900 text-white font-semibold text-sm md:text-lg px-6 py-3 rounded-xl hover:bg-green-300 hover:text-black flex items-center gap-3"
          onClick={openPdf1}
        >
          <FaDownload />
          Download Pdf
        </button>
        <button
          className="bg-green-900 text-white font-semibold text-sm md:text-lg px-6 py-3 rounded-xl hover:bg-green-300 hover:text-black"
          onClick={handleButtonClick}
        >
          Enquire
        </button>
      </section>
    </div>
  </> : <>
    <NotFound /></>}
    </>
  
   )
 }
 
 export default RenderComponent
