import React, { useState, useEffect, useRef } from 'react';
import bottomline from "./asset/bottomline.png"

const Counter = () => {
  // const [isVisible, setIsVisible] = useState(false);
  const countRefs = useRef([]);

  // Scroll visibility detection logic
  const isElementInView = (element) => {
    const rect = element.getBoundingClientRect();
    const viewHeight = window.innerHeight || document.documentElement.clientHeight;
    return rect.top <= viewHeight && rect.bottom >= 0;
  };

  const handleScroll = () => {
    countRefs.current.forEach((ref) => {
      if (ref && isElementInView(ref) && !ref.classList.contains('counter-loaded')) {
        ref.classList.add('counter-loaded');
        animateCount(ref);
      }
    });
  };

  const animateCount = (element) => {
    const target = parseInt(element.textContent);
    let count = 0;
    const interval = setInterval(() => {
      count += target>50? 5 : 1;
      element.textContent = count;
      if (count === target || count > target) {
        clearInterval(interval);
      }
    }, 100); // Adjust the speed as needed
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    // Initial check if the element is in view on load
    handleScroll();
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="px-5 max-w-7xl mx-auto mt-12">
  <div className="flex flex-col items-center gap-2">
  {/* <div className=' my-3 text-center gap-5 flex justify-center items-center text-2xl w-[80%] uppercase yeseva'>
    <div className='w-full h-[2px] bg-gray-200'>
      </div>
      <p className="text-5xl font-bold poppins text-green-700 uppercase tracking-wider yeseva">
      Expertise
    </p>
    <div className='w-full h-[2px] bg-gray-200'>
      </div>
      </div> */}
      <div>
        <p className=' text-3xl lg:text-5xl font-bold text-center poppins text-green-700 uppercase tracking-wider yeseva'>
          Expertise
        </p>
        <img className="ml-[-10px] mb-3 md:block hidden" src={bottomline} alt="" />
        <img className="ml-[-10px] mb-3 md:hidden block w-[300px]" src={bottomline} alt="" />
      </div>
    

    <div className="grid md:mt-0 mt-8 grid-cols-2 sm:grid-cols-4 gap-10">
      {[
        { count: 20, label: "acres of residential area developed" },
        { count: 4, label: "residential projects completed" },
        { count: 18, label: "years of expertise in hillside communities" },
        { count: 250, label: "happy residents" }
      ].map((item, index) => (
        <div
          key={index}
          className="p-2 md:p-6 rounded-lg flex flex-col items-center transition-transform hover:scale-105"
        >
          <div
            className="text-7xl text-green-600 poppins font-semibold "
            ref={(el) => (countRefs.current[index] = el)}
          >
            {item.count}
          </div>
          <div className="font-semibold text-gray-700 text-sm uppercase text-center mt-4">
            {item.label}
          </div>
        </div>
      ))}
    </div>
    <p className="text-justify w-full font-semibold text-gray-600 poppins text-sm md:text-lg my-5">
      With 18 years of expertise, The Shubham Group is dedicated to crafting vibrant communities in Uttarakhand and Himachal Pradesh. Our focus on quality and customer satisfaction ensures every home we build is timeless and harmonious with its surroundings. We are not just selling properties; we are creating an environment around you.
    </p>
  </div>
</div>

  );
};

export default Counter;
